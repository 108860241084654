<template>
    <div>
      <h1>Support Service Page</h1>
      <p>Details about the support services offered.</p>
    </div>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  </style>
  